
import { cloneDeep } from 'lodash';

// For Admin
export const INITIAL_FORM = {
    title: {
        en: '',
        lt: '',
    },
    content: {
        en: '',
        lt: '',
    },
    date: '',
    slug: {
        en: '',
        lt: '',
    }
};

export const prepareInitialDataForPage = async (isEditPage, currentPage) => {
    if (isEditPage) {
        const { title, content, date, slug } = currentPage;

        let form = {
            title,
            content,
            date,
            slug,
        };

        return form;
    } else {
        return cloneDeep(INITIAL_FORM);
    }
};

export const prepareDataForRequest = (form, isEditPage) => {
    let formData = new FormData();

    let payload = {
        title: form.title,
        content: form.content,
        date: form.date,
        slug: form.slug
    };

    formData.append('payload', JSON.stringify(payload));

    if (isEditPage) {
        return payload;
    } else {
        return formData;
    }
};

export const clearForm = (form) => {
    Object.entries(form).forEach(([key, val]) => {
        form = cloneDeep(INITIAL_FORM);

        if (Array.isArray(val)) {
            val.length = 0;
            form[key] = val;
        }
    });

    return form;
};
